import Alpine from 'alpinejs';

window.Alpine = Alpine;

// Wait for the DOM
$(document).ready(() => {

    Alpine.start();

});
